import React from 'react'
import { Tabbar } from 'react-vant'
import { Manager, GoodsCollect , TodoList, } from '@react-vant/icons'
import { useLocation, useNavigate } from 'react-router-dom';

export default function BottomTabBar (){


    const Navigating=useNavigate();
    const urlMap=useLocation();
    const routerArr =["landing","kind","user"];
    const urlName=urlMap.pathname.slice(1)



    const routerChange=(tar)=>{
        switch (tar) {
            case 0:
                Navigating("/landing")
                break;
            case 1:
                Navigating("/kind")
                break;
            case 2:
                Navigating("/user")
                break;
        
            default:
                break;
        }
    }


  return (
    <div className='demo-tabbar' >
      <Tabbar onChange={(tar)=>routerChange(tar)} defaultValue={routerArr.indexOf(urlName)}
      >
        <Tabbar.Item icon={<GoodsCollect  />} >
            热门产品
        </Tabbar.Item>
        <Tabbar.Item icon={<TodoList  />}>
            产品分类
        </Tabbar.Item>
        <Tabbar.Item icon={<Manager  />}>
            订单历史
        </Tabbar.Item>
      </Tabbar>
    </div>
  )
}
