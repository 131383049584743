import React, { useEffect, useState } from 'react'
import BottomTabBar from '../components/bottomTab'
import { Button, Dialog, Empty, Flex, Image, NoticeBar, Notify, Search, SwipeCell, Typography } from 'react-vant'
import { VolumeO } from '@react-vant/icons'
import "../static/css/user.css"
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function UserPage() {
  const [listData,setListData]=useState([1,2,3]);
  const Navigating=useNavigate()


useEffect(()=>{
  try{
    let storageData=JSON.parse(window.localStorage.getItem("value"));
    if(storageData==null){
      storageData=[];
      Notify.show({ type: 'primary', message: '您当前还没有订单哦',duration:6000 })
    }
    setListData(storageData)
  }
  catch(error){
    setListData([]);
  }

},[])

  const handleConfirmData=()=>{
    Dialog.confirm({
      title: '确认提醒',
      message: '您是否要确认此订单?',
    })
      .then(() => {
        axios.get("http://101.42.225.134:7001/robotconfirm")
        .then((val)=>{
          console.log(val);
          Notify.show({type:"success",message:"本订单确认成功！"})
        })
        .catch((error)=>{
          console.log(error);
        })
      })
      .catch(() => {
        console.log('catch')
      })
  }

  
useEffect(()=>{
  window.scrollTo({ top: 0, behavior: 'smooth' });
},[])



  const handleDeleteData=(val)=>{
    return (eve)=>{
      Dialog.confirm({
        title: '删除提醒',
        message: '您确认要删除此条记录，此操作不可逆！',
      })
        .then(() => {
          let newData=listData.filter((value)=>{
            return value.timestamp!=val.timestamp;
          }) 
          window.localStorage.setItem("value",JSON.stringify(newData));
          Notify.show({ type: 'success', message: '该历史信息删除成功！' })
          setListData(newData);
        })
        .catch(() => {
          console.log('catch')
        })
    }
  }



console.log(listData);
  return (
    <div >
        <Search  align="center" placeholder="请输入搜索关键词" background="rgba(101, 178, 252, 1)"/>
        <NoticeBar
          scrollable
          leftIcon={<VolumeO />}
          text='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商品左滑，可进入商品管理界面'
        />
        {
          listData.map((val,index)=>{
            return <>
                <SwipeCell
                  rightAction={
                    <>
                    <Button style={{ height: '100%' }} square type="primary" onClick={handleConfirmData}>
                      确认订单
                    </Button>
                    <Button style={{ height: '100%' }} square type="danger" onClick={handleDeleteData(val)}>
                      删除订单
                    </Button>
                    </>
                    
                  }
                >
                  <Flex className="demo-product-card" align="stretch">
                    <Image src="https://wimg.588ku.com/gif/20/08/27/2be2d5e0555593cd2b6eac327a544edf.gif" className="demo-product-card__img" />
                    <Flex direction="column" justify="between" className="demo-product-card__content">
                      <div>
                        <Typography.Title level={5}>{index+1}号商品</Typography.Title>
                        <Typography.Text type="secondary">
                          很有用的商品
                        </Typography.Text>
                      </div>
                      <Flex justify="between" align="center">
                        <Typography.Text strong size="lg">
                          ¥0.00
                        </Typography.Text>
                        <Typography.Text size="sm" type="secondary">
                          x1
                        </Typography.Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </SwipeCell>
            </>
          })
        }
        <div className='btnBox'
        style={{display:listData.length===0 ? "block" : "none"}}>
          <Empty description="暂无账单信息" />
        </div>
    
        <BottomTabBar/>
    </div>
  )
}
