import React, { useEffect, useState } from 'react'
import BottomTabBar from '../components/bottomTab'
import {CouponList, Tabs,Search, Swiper,Toast, ProductCard, Tag, Popup, Dialog, Notify } from 'react-vant'
import { shopStore } from '../database/shopInfo';
import img03 from "../static/images/诚邀各路英才.gif"
import img02 from "../static/images/新湖会议开始啦.gif"
import img01 from "../static/images/Online Meeting.gif"
import "../static/css/landing.css"
import axios from 'axios';

export default function Landing() {
  const [value, setValue] = useState('');
  const { kind2List,kind1List,kind3List,kind4List} = shopStore()

  const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']


  const items = (
  <>
      <Swiper.Item key={1}>
      <img src={img01} alt="" />
      </Swiper.Item>
      <Swiper.Item key={2}>
      <img src={img02} alt="" />
      </Swiper.Item>
      <Swiper.Item key={3}>
      <img src={img03} alt="" />
      </Swiper.Item>
  </>
  )



  
  const data3 = [
    {
      id: 0,
      condition: '规格：小型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '不加急',

    },
    {
      id: 1,
      condition: '规格：大型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '不加急',

    },
    {
      id: 2,
      condition: '规格：小型',
      value: 150,
      name: '数量：两件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '不加急',

    },
    {
      id: 3,
      condition: '规格：小型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '加急',

    },
    {
      id: 4,
      condition: '规格：大型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '加急',

    }
]



const [coupons, setCoupons] = useState(data3)
const [chosenCoupon, setChosenCoupon] = useState(-1)
const [visible, setVisible] = useState([false,{}])



useEffect(()=>{
  window.scrollTo({ top: 0, behavior: 'smooth' });
},[])

const onChange = (index) => {
  const storageData=visible[1];
  setVisible([false,{}])
  setChosenCoupon(index)
  console.log(index);
  if(index!=-1){
    Dialog.confirm({
      title: '订单确认',
      message: '您是否确定购买，若选择确定，小车将会立即给您送货',
    })
    .then(async() => {
      try {
        const response = await 
        axios.get(`http://101.42.225.134:7001/robot?title=${storageData.title}&choice=${index}`)
        .then(()=>{
          let preData=JSON.parse(window.localStorage.getItem("value"));
          let jsonData='';
          const timestamp = Date.now();
          if(preData!=null)
          {
            jsonData=JSON.stringify([...preData,{data:storageData,choice:chosenCoupon,timestamp}]);
          }
          else{
            jsonData=JSON.stringify([{data:storageData,choice:chosenCoupon,timestamp}]);
          }
          window.localStorage.setItem("value",jsonData);
          Notify.show({type:"success",message:"订单生成成功，产品会尽快送达，您可到历史信息进行订单管理"})
        })
      } 
      catch (error) {
        console.error('消息发送失败', error);
      }
    })
    .catch(() => {
        console.log('catch')
    })
  }
}








  return (
    <div>
        <Search  align="center" placeholder="请输入搜索关键词" background="rgba(101, 178, 252, 1)"/>

      <div className="demo-swiper">
        <Swiper 
            sticky  autoplay={7000}
            scrollspy={{ autoFocusLast: true, reachBottomThreshold: 50 }}
        >
                <Swiper.Item key={1}>
                <img src={img01} alt="" />
                </Swiper.Item>
                <Swiper.Item key={2}>
                <img src={img02} alt="" />
                </Swiper.Item>
                <Swiper.Item key={3}>
                <img src={img03} alt="" />
                </Swiper.Item>
        </Swiper>
      </div>
        <Tabs active={1} className='bottom_last'>
            <Tabs.TabPane title='食品' key={1}>
              {
                kind1List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
            <Tabs.TabPane title='饮品' key={2}>
              {
                kind2List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
            <Tabs.TabPane title='电子产品' key={3}>
              {
                kind3List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
            <Tabs.TabPane title='临时衣物' key={4}>
              {
                kind4List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
        </Tabs>


        <BottomTabBar/>
        <Popup
          round
          position='bottom'
          style={{ height: '90%', paddingTop: 4 }}
          visible={visible[0]}
          onClose={() => setVisible([false,{}])}
        >
        <CouponList
          chosenCoupon={chosenCoupon}
          coupons={coupons}
          // disabledCoupons={disabledCoupons}
          onChange={onChange}
          closeButtonText={"取消选择"}
          showExchangeBar={false}
        />
      </Popup>
    </div>


  )
}
