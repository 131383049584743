

import { create } from 'zustand'//导入zustand的create方法

export const shopStore = create((set) => ({
    kind1List:[
    {num:139,price:0,description:"有很大作用的东西",title:"食物 1号",link:"https://tse1-mm.cn.bing.net/th/id/OIP-C.2brGH4qrr95zT8IAj39HcgHaHa?w=196&h=196&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:139,price:0,description:"有很大作用的东西",title:"食物 2号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.0MY5j9I0tNsjQyfFSwtqKgHaHa?w=210&h=210&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:139,price:0,description:"有很大作用的东西",title:"食物 3号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.iY500ZxEIxpNtK4vbPa47AHaGT?w=241&h=205&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:139,price:0,description:"有很大作用的东西",title:"食物 4号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.uG6-Ngfh9UmK3kAD_Nul5gHaHa?w=193&h=193&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:139,price:0,description:"有很大作用的东西",title:"食物 5号",link:"https://tse1-mm.cn.bing.net/th/id/OIP-C.2brGH4qrr95zT8IAj39HcgHaHa?w=196&h=196&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:139,price:0,description:"有很大作用的东西",title:"食物 6号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.neWsGqZE3dL_H6SSpnuLewHaHa?w=196&h=197&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:139,price:0,description:"有很大作用的东西",title:"食物 7号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.mESUAKJ4LLC25fBHAFZFkgHaHa?w=201&h=200&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:139,price:0,description:"有很大作用的东西",title:"食物 8号",link:"https://tse1-mm.cn.bing.net/th/id/OIP-C.2brGH4qrr95zT8IAj39HcgHaHa?w=196&h=196&c=7&r=0&o=5&dpr=1.3&pid=1.7",
    tag:["食品","临时使用"],tagTitle:"热销"},

    ],
    kind2List:[
        {num:139,price:0,description:"有很大作用的东西",title:"白酒 1号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.SEgOzjWFNuXGhWw55MuwJgHaHa?w=210&h=210&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"白酒 2号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.wlI-lpYaYiU_dPeC0byiPwHaHp?w=206&h=211&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"红酒 1号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.a5hyBvzu8kpx2qJFElXOrgHaHa?w=209&h=209&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"红酒 2号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.-ZbGYeyKVmJpuMESnpbXEQHaIR?w=187&h=207&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"饮料 1号",link:"https://tse3-mm.cn.bing.net/th/id/OIP-C.1lHrpuVOxVBKcMrm5zyEMQHaGR?w=237&h=201&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"饮料 2号",link:"https://tse3-mm.cn.bing.net/th/id/OIP-C.43_fwxeBY6iOgfbmKmJvbQHaHa?w=200&h=200&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"矿泉水 1号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.IhLX6sc84tj-t8Nj-OOMvQHaHa?w=214&h=214&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"矿泉水 2号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.2z_Id2K6mK0O3Qu6vvcHXgHaIZ?w=192&h=218&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["饮品","临时使用"],tagTitle:"热销"},
    ],
    kind3List:[
        {num:139,price:0,description:"有很大作用的东西",title:"充电宝 1号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.k5JKUmkYJH6EcLYkj3O-DgHaHa?w=215&h=215&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"充电宝 2号",link:"https://tse3-mm.cn.bing.net/th/id/OIP-C.650jS7o_O2V4HiwQZofydQHaH-?w=199&h=215&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"电子翻译器 1号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.mbUicLR5Q40162AORlJBuwHaHa?w=209&h=209&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"电子翻译器 2号",link:"https://tse3-mm.cn.bing.net/th/id/OIP-C.w2YzoSefDQGp8qEalLl4CAHaHa?w=209&h=209&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"记录笔 1号",link:"https://tse1-mm.cn.bing.net/th/id/OIP-C.Id7HoQWk_Cxrvn6nlYj_kAHaHa?w=201&h=201&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"记录笔 2号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.C-3lrPoKPbz9Fj0Fnn5dQAHaHa?w=217&h=217&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"记录笔 1号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.HhB9J3oUPkz-1Ks2SbWongHaHa?w=207&h=207&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"有很大作用的东西",title:"记录本 2号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.iM0iniE5WWYSFo0Vb7B40QHaHa?w=208&h=208&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["电子","临时使用"],tagTitle:"热销"},
    ],
    kind4List:[
        {num:139,price:0,description:"用于防晒的",title:"防晒衣 1号",link:"https://tse4-mm.cn.bing.net/th/id/OIP-C.RcucU2vN7LRYp1Cb01BwjgHaHa?w=218&h=218&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["防晒","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"用于防雨的",title:"雨衣 1号",link:"https://tse1-mm.cn.bing.net/th/id/OIP-C.2aJQrFuL97A02NQTeBEwaQHaHa?w=204&h=203&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["防雨","临时使用"],tagTitle:"即将结束"},
        {num:139,price:0,description:"用于背的",title:"背包 1号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.tCo727ysdAbzgnhx0cfycwHaIA?w=197&h=213&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["背包","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"用于穿的",title:"赛会文化衫 1号",link:"https://tse1-mm.cn.bing.net/th/id/OIP-C.6gxZDhTUj3aTR7_9j8I5HQHaHa?w=196&h=196&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["赛会标志","临时使用"],tagTitle:"即将结束"},
        {num:139,price:0,description:"用于防晒的",title:"防晒衣 2号",link:"https://pics.17qcc.com/imgextra/product/202003/19/14849736031749.jpg",
        tag:["防晒","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"用于防雨的",title:"雨衣 2号",link:"https://tse2-mm.cn.bing.net/th/id/OIP-C.Dnzuj0YL5lyHniiLceFqaQHaHa?w=210&h=211&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["防雨","临时使用"],tagTitle:"即将结束"},
        {num:139,price:0,description:"用于背的",title:"背包 2号",link:"https://img.zcool.cn/community/014dd45771d3ef0000018c1b797ffa.jpg@1280w_1l_2o_100sh.jpg",
        tag:["背包","临时使用"],tagTitle:"热销"},
        {num:139,price:0,description:"用于穿的",title:"赛会文化衫 2号",link:"https://tse1-mm.cn.bing.net/th/id/OIP-C.ODshtdyI7sX4eKHZHI6HgAHaGQ?w=255&h=214&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        tag:["赛会标志","临时使用"],tagTitle:"即将结束"},

    ],
    
  	addUserInfo: (val) => {    
        set((state)=>({kind1List:val}));
  	},//定义仓库的相应reducer


    
    
}))
