import React, { useEffect, useState } from 'react'
import BottomTabBar from '../components/bottomTab'
import { CouponList, Dialog,  Notify,  Popup, ProductCard, Search, Sidebar ,Tag,Toast} from 'react-vant';
import { shopStore } from '../database/shopInfo';

import "../static/css/kind.css"
import axios from 'axios';
export default function KindS() {


  const [active, setActive] = useState(0);

  const { kind2List,kind1List,kind3List,kind4List} = shopStore()


  const data3 = [
    {
      id: 0,
      condition: '规格：小型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '不加急',

    },
    {
      id: 1,
      condition: '规格：大型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '不加急',

    },
    {
      id: 2,
      condition: '规格：小型',
      value: 150,
      name: '数量：两件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '不加急',

    },
    {
      id: 3,
      condition: '规格：小型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '加急',

    },
    {
      id: 4,
      condition: '规格：大型',
      value: 150,
      name: '数量：一件',
      startAt: 1688017000,
      endAt: 1688104000,
      valueDesc: '加急',

    }
]

useEffect(()=>{
  window.scrollTo({ top: 0, behavior: 'smooth' });
},[])

  

const [coupons, setCoupons] = useState(data3)
const [chosenCoupon, setChosenCoupon] = useState(-1)
const [visible, setVisible] = useState([false,{}])

const onChange = (index) => {
  const storageData=visible[1]

  setVisible([false,{}])
  setChosenCoupon(index)
  console.log(index);
  if(index!=-1){
    Dialog.confirm({
      title: '订单确认',
      message: '您是否确定购买，若选择确定，小车将会立即给您送货',
    })
      .then(async() => {
        try {
          const response = await 
          axios.get(`http://101.42.225.134:7001/robot?title=${storageData.title}&choice=${index}`)
          .then(()=>{
            let preData=JSON.parse(window.localStorage.getItem("value"));
            let jsonData='';
            const timestamp = Date.now();
            if(preData!=null)
            {
              jsonData=JSON.stringify([...preData,{data:storageData,choice:chosenCoupon,timestamp}]);
            }
            else{
              jsonData=JSON.stringify([{data:storageData,choice:chosenCoupon,timestamp}]);
            }
            window.localStorage.setItem("value",jsonData);
            Notify.show({type:"success",message:"订单生成成功，产品会尽快送达，您可到历史信息进行订单管理"})
          })
        } catch (error) {
          console.error('消息发送失败', error);
        }
      })
      .catch(() => {
        console.log('catch')
      })
  }
}




  
  return (
    <div>
        <Search  align="center" placeholder="请输入搜索关键词" background="rgba(101, 178, 252, 1)"/>
        <Sidebar
        className='bottom_last'
          value={active}
          onChange={(v) => {
            setActive(v);
            Toast.info(`分类区域 ${v + 1}`);
          }}
        >
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="分类1">
              {
                kind1List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
          </Sidebar.Item>
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="分类2">
              {
                kind2List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
          </Sidebar.Item>
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="分类3">
              {
                kind3List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
          </Sidebar.Item>
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="分类4">
              {
                kind4List.map((val,index)=>{
                  return     <ProductCard
                  key={index}
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible([true,val])}}
                  footer={
                    <>
                      {
                        val.tag.map((value,idx)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }} key={idx}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
          </Sidebar.Item>
          


        </Sidebar>
        <BottomTabBar/>
      <Popup
          round
          position='bottom'
          style={{ height: '90%', paddingTop: 4 }}
          visible={visible[0]}
          onClose={() => setVisible([false,{}])}
        >
        <CouponList
          chosenCoupon={chosenCoupon}
          coupons={coupons}
          // disabledCoupons={disabledCoupons}
          onChange={onChange}
          closeButtonText={"取消选择"}
          showExchangeBar={false}
        />
      </Popup>
    </div>
  )
}
